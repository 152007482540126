import CopyToClipboard from "@/components/shared/copy-to-clipboard";
import DesoAvatar from "@/components/shared/deso-avatar";
import Spinner from "@/components/shared/spinner";
import UserCoinPrice from "@/components/shared/user-coin-price";
import UserDesoBalance from "@/components/shared/user-deso-balance";
import UserLink from "@/components/shared/user-link";
import WalletLink from "@/components/shared/wallet-link";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { cn } from "@/lib";
import { useLazyQuery, useQuery } from "@apollo/client";
import { UserIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { client } from "../../graphql/client";
import {
  AccountExtendedByPublicKeyDocument,
  AccountFollowersByPublicKeyDocument,
} from "../../graphql/codegen/graphql";
import { useMobile } from "../../hooks/use-mobile";
import {
  abbreviateNumber,
  formatDisplayName,
  shortenLongWord,
} from "../../utils/helpers";
import { Skeleton } from "../ui/skeleton";

interface SocialCardProps {
  size?: "xs" | "sm";
  publicKey: string;
  hoverable?: boolean;
  showAvatar?: boolean;
  truncateName?: boolean;
  linkType?: "user" | "validator";
}

export function SocialCard({
  size = "xs",
  publicKey,
  hoverable = true,
  showAvatar = false,
  truncateName = false,
  linkType = "user",
}: SocialCardProps) {
  const { isMobile } = useMobile();
  const [open, setOpen] = useState(false);

  const { loading: loadingUser, data: user } = useQuery(
    AccountExtendedByPublicKeyDocument,
    {
      client,
      variables: {
        publicKey,
      },
    },
  );

  const [fetchFollowersLazy, { loading: loadingFollowers, data: followers }] =
    useLazyQuery(AccountFollowersByPublicKeyDocument, {
      client,
      variables: {
        publicKey,
      },
    });

  useEffect(() => {
    if (open || !hoverable) {
      fetchFollowersLazy();
    }
  }, [open, hoverable]);

  const userDetails = user?.accountByPublicKey;
  const username = userDetails?.username || "";
  const displayName = formatDisplayName(username, publicKey, true);

  const cardContent = (
    <div className="bg-card border p-4 border-transparent border-solid rounded-lg relative flex items-start flex-col lg:flex-row gap-4">
      {loadingUser ? (
        <div className="flex items-center justify-center h-[120px] w-full">
          <Spinner size={32} className="m-auto" />
        </div>
      ) : (
        <>
          <DesoAvatar
            publicKey={publicKey}
            username={username || ""}
            linkType={linkType}
            size={48}
          />

          <div className="ml-0">
            <div className="flex items-center justify-between">
              <h4 className="text-sm font-semibold text-muted-foreground">
                <UserLink
                  linkType={linkType}
                  username={username}
                  pubKey={publicKey}
                  className="hover:underline"
                >
                  <>
                    {displayName}

                    <UserCoinPrice
                      coinPriceNanos={
                        user?.accountByPublicKey?.profile?.coinPriceDesoNanos
                      }
                      loading={loadingUser}
                      badgeClassName="text-xs"
                    />
                  </>
                </UserLink>
              </h4>
            </div>
            <div className="text-sm text-muted my-1 mt-2">
              <CopyToClipboard text={publicKey}>
                <span className="text-xs flex font-mono items-center">
                  {shortenLongWord(publicKey, 6, 6)}
                </span>
              </CopyToClipboard>
            </div>

            <UserDesoBalance
              balanceNanos={user?.accountByPublicKey?.desoBalance?.balanceNanos}
              className="text-xs mt-2"
            />

            <div className="flex items-center pt-2">
              <div className="flex items-center">
                <UserIcon className="mr-2 h-4 w-4 text-muted" />{" "}
                <div className="flex items-center text-xs lowercase text-muted">
                  {loadingFollowers ? (
                    <Skeleton className="h-[12px] w-[20px] inline-block mr-1" />
                  ) : (
                    abbreviateNumber(
                      followers?.accountByPublicKey?.followers.totalCount || 0,
                    )
                  )}{" "}
                  Followers
                </div>
              </div>
              <div className="flex items-center ml-4">
                <UserIcon className="mr-2 h-4 w-4 text-muted" />{" "}
                <div className="flex items-center text-xs lowercase text-muted">
                  {loadingFollowers ? (
                    <Skeleton className="h-[12px] w-[20px] inline-block mr-1" />
                  ) : (
                    abbreviateNumber(
                      followers?.accountByPublicKey?.following.totalCount || 0,
                    )
                  )}{" "}
                  Following
                </div>
              </div>
            </div>

            <div className="pt-4">
              <UserLink username={username} pubKey={publicKey}>
                <Button size="sm" variant="outline" className="mr-2">
                  View Activity
                </Button>
              </UserLink>

              <WalletLink user={userDetails}>
                <Button size="sm" variant="ghost">
                  View Wallet
                </Button>
              </WalletLink>
            </div>
          </div>
        </>
      )}
    </div>
  );

  if (!hoverable) {
    return cardContent;
  }

  const triggerBtn = (
    <div className={cn("flex items-center", truncateName && "w-full")}>
      {showAvatar && (
        <DesoAvatar
          className="mr-1"
          publicKey={publicKey}
          clickable={true}
          username={username}
          linkType={linkType}
        />
      )}

      {loadingUser ? (
        <Skeleton className="w-[80px] h-[14px] my-0 mx-auto" />
      ) : (
        <UserLink
          username={username}
          pubKey={publicKey}
          linkType={linkType}
          className={cn(
            buttonVariants({ variant: "link" }),
            truncateName && "truncate",
            size === "xs" && "text-xs",
            size === "sm" && "text-sm",
            "text-muted-foreground m-0 p-0 h-auto w-full",
          )}
        >
          {displayName}
        </UserLink>
      )}
    </div>
  );

  if (isMobile) {
    return triggerBtn;
  }

  return (
    <HoverCard open={open} onOpenChange={setOpen}>
      <HoverCardTrigger asChild>{triggerBtn}</HoverCardTrigger>

      <HoverCardContent className="w-100">{cardContent}</HoverCardContent>
    </HoverCard>
  );
}

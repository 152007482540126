import DesoAvatar from "@/components/shared/deso-avatar";
import { GemIcon, Globe, UserIcon } from "lucide-react";
import {
  abbreviateNumber,
  formatDisplayName,
  linkToDisplayApp,
  shortenLongWord,
} from "../../utils/helpers";
import { InfoTooltip } from "../shared/info-tooltip";
import { Button } from "../ui/button";
import {
  AccountDetailsFragment,
  AccountFollowersByPublicKeyDocument,
  CoreAccountFieldsFragment,
  MyStakeSummariesDocument,
  ProfileCcStatsDocument,
} from "../../graphql/codegen/graphql";
import { Link } from "react-router-dom";
import CopyToClipboard from "@/components/shared/copy-to-clipboard";
import range from "lodash/range";
import { Skeleton } from "@/components/ui/skeleton";
import { useQuery } from "@apollo/client";
import { client } from "../../graphql/client";
import {
  desoNanosToDeso,
  desoNanosToUSD,
  formatDecimalValue,
} from "../../utils/currency";
import { useContext } from "react";
import { MarketDataContext } from "../../contexts/market-data";
import { DIAMOND_APP_URL } from "../../utils/constants";
import WalletLink from "@/components/shared/wallet-link";
import * as React from "react";
import UserDesoBalance from "@/components/shared/user-deso-balance";

interface ProfileCardProps {
  user: AccountDetailsFragment | null;
  publicKey: string;
}

const SKELETON_NUM_OF_CARDS = 4;

const ProfileCard = ({ user, publicKey }: ProfileCardProps) => {
  const { exchangeRate, loading: loadingMarketData } =
    useContext(MarketDataContext);

  const { loading: loadingProfileStats, data: profileCoinStats } = useQuery(
    ProfileCcStatsDocument,
    {
      client,
      variables: {
        username: user?.username || "",
      },
    },
  );
  const { loading: loadingFollowers, data: followers } = useQuery(
    AccountFollowersByPublicKeyDocument,
    {
      client,
      variables: {
        publicKey,
      },
    },
  );
  const { data: myStakeSummaries } = useQuery(MyStakeSummariesDocument, {
    client,
    variables: {
      filter: {
        staker: {
          publicKey: {
            equalTo: publicKey,
          },
        },
      },
    },
  });

  const displayName = formatDisplayName(user?.username, publicKey);
  const coverImageURL =
    user?.extraData?.FeaturedImageURL || "/assets/img/image-cover-default.jpg";

  const circulatingSupply = desoNanosToDeso(
    profileCoinStats?.accountByUsername?.profile?.ccCoinsInCirculationNanos ||
      0,
  );

  return (
    <div className="rounded-3xl mb-12 bg-card">
      <div
        className="h-[260px] border-t dark:border-t-white/20 border-b border-b-background bg-black bg-no-repeat rounded-tl-3xl rounded-tr-3xl bg-cover bg-center"
        style={{ backgroundImage: `url(${coverImageURL})` }}
      />

      <div className="flex flex-col-reverse gap-8 lg:gap-0 lg:flex-row-reverse justify-between items-start p-6 relative">
        <div className="flex justify-end items-end flex-col gap-4">
          <CopyToClipboard text={publicKey}>
            <span className="text-sm flex font-mono items-center">
              {shortenLongWord(publicKey, 10, 10)}
            </span>
          </CopyToClipboard>
        </div>

        <div className="flex flex-col lg:flex-row items-start gap-2">
          <div className="relative -top-[60px]">
            <div className="flex flex-col-reverse items-center justify-center gap-3">
              {user?.username && (
                <div className="gap-5 md:gap-3 flex relative z-10">
                  <InfoTooltip
                    text={linkToDisplayApp().replace("https://", "")}
                    triggerBtn={
                      <Link
                        to={`${linkToDisplayApp()}/u/${user.username}`}
                        target="_blank"
                      >
                        <GemIcon
                          size={18}
                          className="opacity-70 hover:opacity-100"
                        />
                      </Link>
                    }
                  />

                  <InfoTooltip
                    text="desocialworld.com"
                    triggerBtn={
                      <Link
                        to={`https://desocialworld.com/u/${user.username}`}
                        target="_blank"
                      >
                        <Globe
                          size={18}
                          className="opacity-70 hover:opacity-100"
                        />
                      </Link>
                    }
                  />
                </div>
              )}
              <DesoAvatar
                publicKey={publicKey}
                username={displayName}
                size={74}
                className="border-4 border-background bg-background"
                clickable={true}
              />
            </div>
          </div>

          <div className="relative -mt-6 lg:mt-0">
            <div className="flex flex-col items-start mb-3">
              <h1 className="text-lg text-black dark:text-white font-medium mb-2 flex items-center">
                @{displayName}
              </h1>

              <div className="flex items-start gap-2 flex-col">
                <div className="flex flex-col sm:flex-row sm:items-center gap-2">
                  <UserDesoBalance
                    balanceNanos={user?.desoBalance?.balanceNanos}
                    label="Unstaked"
                  />
                  <UserDesoBalance
                    balanceNanos={
                      myStakeSummaries?.myStakeSummaries?.nodes[0]
                        ?.totalStake || 0
                    }
                    label="Staked"
                  />
                  {myStakeSummaries?.myStakeSummaries?.nodes[0]
                    ?.totalLockedStake &&
                    !!parseInt(
                      myStakeSummaries?.myStakeSummaries?.nodes[0]
                        ?.totalLockedStake,
                    ) && (
                      <UserDesoBalance
                        balanceNanos={
                          myStakeSummaries?.myStakeSummaries?.nodes[0]
                            ?.totalLockedStake || 0
                        }
                        label="Locked Stake"
                      />
                    )}
                </div>
                <UserDesoBalance
                  balanceNanos={(
                    BigInt(user?.desoBalance?.balanceNanos || 0) +
                    BigInt(
                      myStakeSummaries?.myStakeSummaries?.nodes[0]
                        ?.totalStake || 0,
                    ) +
                    BigInt(
                      myStakeSummaries?.myStakeSummaries?.nodes[0]
                        ?.totalLockedStake || 0,
                    )
                  ).toString()}
                  label="Total"
                />
              </div>
            </div>

            <div className="flex items-center">
              <div className="flex items-center">
                <UserIcon className="mr-2 h-4 w-4 opacity-70" />{" "}
                <div className="flex items-center text-sm lowercase text-muted">
                  {loadingFollowers ? (
                    <Skeleton className="h-[16px] w-[40px] inline-block mr-1" />
                  ) : (
                    <span className="mr-1">
                      {formatDecimalValue(
                        followers?.accountByPublicKey?.followers?.totalCount ||
                          0,
                        0,
                      )}
                    </span>
                  )}
                  Followers
                </div>
              </div>

              <div className="flex items-center ml-4">
                <UserIcon className="mr-2 h-4 w-4 opacity-70" />{" "}
                <div className="flex items-center text-sm lowercase text-muted">
                  {loadingFollowers ? (
                    <Skeleton className="h-[16px] w-[30px] inline-block mr-1" />
                  ) : (
                    <span className="mr-1">
                      {formatDecimalValue(
                        followers?.accountByPublicKey?.following?.totalCount ||
                          0,
                        0,
                      )}
                    </span>
                  )}
                  Following
                </div>
              </div>
            </div>

            <div className="pt-4">
              <Button size="sm" variant="outline" className="mr-2 p-0">
                <WalletLink
                  user={user || ({ publicKey } as CoreAccountFieldsFragment)}
                  className="py-4 px-4"
                >
                  View Wallet
                </WalletLink>
              </Button>

              {user?.username && (
                <Button size="sm" variant="ghost" className="p-0">
                  <Link
                    to={`${DIAMOND_APP_URL}/u/${user.username}`}
                    target="_blank"
                    className="py-4 px-4"
                  >
                    View Profile
                  </Link>
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="font-mono grid grid-cols-1 gap-4 lg:grid-cols-4 w-full items-center justify-between text-xs text-muted p-4 border-t border-border-light">
        {loadingProfileStats || loadingMarketData || !exchangeRate ? (
          range(SKELETON_NUM_OF_CARDS).map((_, i) => (
            <Skeleton className="h-[20px] w-full" key={i} />
          ))
        ) : (
          <>
            <div className="flex items-center lg:justify-start">
              <span className="flex items-center">
                <InfoTooltip
                  text={`The USD value of $${displayName} creator coin`}
                />
                <span className="inline-block ml-2">
                  Coin Price ≈ $
                  {abbreviateNumber(
                    desoNanosToUSD(
                      profileCoinStats?.accountByUsername?.profile
                        ?.coinPriceDesoNanos || 0,
                      exchangeRate,
                    ),
                  )}
                </span>
              </span>
            </div>

            <div className="flex items-center lg:justify-start">
              <span className="flex items-center">
                <InfoTooltip
                  text={`Unique users who hold $${displayName} creator coin`}
                />
                <span className="inline-block ml-2">
                  Coin Holders ≈{" "}
                  {profileCoinStats?.accountByUsername
                    ?.creatorCoinBalancesAsCreator.totalCount || 0}
                  {""} Holders
                </span>
              </span>
            </div>

            <div className="flex items-center lg:justify-center">
              <span className="flex items-center">
                <InfoTooltip text="Total coins in circulation" />
                <span className="inline-block ml-2">
                  Coin Circulation ≈ {formatDecimalValue(circulatingSupply)}
                </span>
              </span>
            </div>

            <div className="flex items-center lg:justify-end">
              <span className="flex items-center">
                <InfoTooltip text="Calculated by Creator Coin Price x Circulating Supply" />
                <span className="inline-block ml-2">
                  Coin Marketcap = $
                  {abbreviateNumber(
                    desoNanosToUSD(
                      circulatingSupply *
                        profileCoinStats?.accountByUsername?.profile
                          ?.coinPriceDesoNanos || 0,
                      exchangeRate,
                    ),
                  )}
                </span>
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileCard;

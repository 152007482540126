import { useState, useMemo } from "react";
import { useCombobox, useMultipleSelection } from "downshift";
import { cn } from "@/lib";
import { TxnAutocompleteItem } from "./transaction-advanced-filters";

interface TransactionTypeAutocompleteProps {
  selectedItems: Array<TxnAutocompleteItem>;
  setSelectedItems: (types: Array<TxnAutocompleteItem>) => void;
  availableTypes: Array<TxnAutocompleteItem>;
}

const TransactionTypeAutocomplete = ({
  selectedItems,
  setSelectedItems,
  availableTypes,
}: TransactionTypeAutocompleteProps) => {
  const getFilteredTransactionTypes = (
    selectedItems: Array<TxnAutocompleteItem>,
    inputValue: string,
  ) => {
    const lowerCasedInputValue = inputValue.trim().toLowerCase();

    return availableTypes.filter((item: TxnAutocompleteItem) => {
      const selectedTypes = selectedItems.map((e) => e.type);

      return (
        !selectedTypes.includes(item.type) &&
        (item.desc.toLowerCase().includes(lowerCasedInputValue) ||
          item.displayName.toLowerCase().includes(lowerCasedInputValue) ||
          item.type.toLowerCase().includes(lowerCasedInputValue))
      );
    });
  };

  const [inputValue, setInputValue] = useState<string>("");

  const items = useMemo<Array<TxnAutocompleteItem>>(
    () => getFilteredTransactionTypes(selectedItems, inputValue),
    [selectedItems, inputValue],
  );

  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } =
    useMultipleSelection({
      selectedItems,
      onStateChange({ selectedItems: newSelectedItems, type }) {
        switch (type) {
          case useMultipleSelection.stateChangeTypes
            .SelectedItemKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
          case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
            setSelectedItems(newSelectedItems || []);
            break;
          default:
            break;
        }
      },
    });

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useCombobox({
    items,
    itemToString(item) {
      return item ? item.desc : "";
    },
    defaultHighlightedIndex: 0, // after selection, highlight the first item.
    selectedItem: null,
    inputValue,
    stateReducer(state, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true, // keep the menu open after selection.
            highlightedIndex: 0, // with the first option highlighted.
          };
        default:
          return changes;
      }
    },
    onStateChange({
      inputValue: newInputValue,
      type,
      selectedItem: newSelectedItem,
    }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (newSelectedItem) {
            setSelectedItems([...selectedItems, newSelectedItem]);
            setInputValue("");
          }
          break;

        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(newInputValue || "");

          break;
        default:
          break;
      }
    },
  });

  return (
    <div className="w-full relative">
      <div className="flex flex-col gap-1">
        <div className="shadow-sm bg-card rounded-2xl inline-flex gap-2 items-center flex-wrap p-2">
          {selectedItems.map(
            (selectedItemForRender: TxnAutocompleteItem, index: number) => {
              return (
                <span
                  className="bg-primary dark:bg-card-foreground text-xs text-muted-foreground rounded-full px-3 py-2 focus:bg-secondary"
                  key={`selected-item-${index}`}
                  {...getSelectedItemProps({
                    selectedItem: selectedItemForRender,
                    index,
                  })}
                >
                  {selectedItemForRender.displayName}
                  <span
                    className="px-1 ml-2 text-xs cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      removeSelectedItem(selectedItemForRender);
                    }}
                  >
                    &#10005;
                  </span>
                </span>
              );
            },
          )}
          <div className="flex gap-0.5 grow">
            <input
              placeholder="Enter transaction type..."
              className="w-full bg-transparent placeholder:text-muted text-sm px-2 focus:ring-0 focus:outline-none"
              {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
            />
            <button
              aria-label="toggle menu"
              className="px-2"
              type="button"
              {...getToggleButtonProps()}
            >
              &#8595;
            </button>
          </div>
        </div>
      </div>
      <ul
        className={`absolute w-full border border-border rounded-lg bg-card mt-3 shadow-md max-h-80 overflow-y-scroll p-0 z-10 ${
          !(isOpen && items.length) && "hidden"
        }`}
        {...getMenuProps()}
      >
        {isOpen &&
          items.map((item, index) => (
            <li
              className={cn(
                highlightedIndex === index &&
                  "hover:bg-secondary dark:hover:bg-accent",
                selectedItem === item && "font-bold",
                "py-2 px-2 flex flex-col lg:flex-row items-start lg:items-center hover:bg-secondary dark:hover:bg-accent justify-between",
              )}
              key={item.type}
              {...getItemProps({ item, index })}
            >
              <div className="flex items-center">
                <div className="text-sm text-muted-foreground">
                  {item.displayName}
                </div>
              </div>
              <div className="lg:text-right">
                <span className="text-xs text-muted font-mono">
                  {item.desc}
                </span>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default TransactionTypeAutocomplete;

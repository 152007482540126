import { Transaction } from "../../graphql/codegen/graphql";
import maxBy from "lodash/maxBy";
import range from "lodash/range";
import { cn } from "@/lib";
import { TRANSACTION_TYPE, TRANSACTION_TYPES_BY_INDEX } from "../../types";
import { DiamondPostLink } from "./diamond-post-link";
import { DiamondNFTLink } from "./diamond-nft-link";
import {
  baseUnitsToTokensFormatted,
  desoNanosToDeso,
} from "../../utils/currency";
import { Gem } from "lucide-react";
import {
  formatTxnUsername,
  parsePublicKeyBytesToPublicKey,
  publicKeyByteArrayToBase58Check,
  scaledExchangeRateToPriceString,
  toFixedTrimTrailingZeroes,
} from "../../utils/helpers";
import { SocialCard } from "@/components/shared/social-card";
import { isMaybeDeSoPublicKey } from "deso-protocol/src/internal";
import { Link } from "react-router-dom";
import { Badge } from "../ui/badge";
import { FOCUS_PUBLIC_KEY, USDC_PUBLIC_KEY } from "../../utils/constants";

export interface AffectedPublicKey {
  publicKey: string;
  __typename: string;
}

export interface BasicTransferOutput {
  amount_nanos: string;
  public_key: string;
}

enum AssociationReactionValue {
  LIKE = "👍",
  DISLIKE = "👎",
  LOVE = "❤️",
  LAUGH = "😂",
  ASTONISHED = "😲",
  SAD = "😥",
  ANGRY = "😠",
}

interface ActivityActionProps {
  item: Transaction;
  wrap?: boolean;
  maxInnerTxnsToDisplay?: number;
  showAllInnerTxns?: boolean;
}

const isZeroPublicKey = (publicKey: Array<number>) => {
  return publicKey.every((e) => e === 0);
};

const getFullActionForAssociation = (txn: any, action: JSX.Element) => {
  const { AppPublicKeyBase58Check, AssociationType, AssociationValue } =
    txn.txIndexMetadata || {};
  const subAction = (
    <div className="flex flex-col gap-1 mb-2 border border-border-light text-xs rounded-lg w-full max-w-[400px]">
      {AppPublicKeyBase58Check && (
        <div className="flex text-xs p-2 pb-1">
          <span className="mr-1">App: </span>
          <SocialCard showAvatar={true} publicKey={AppPublicKeyBase58Check} />
        </div>
      )}
      <div className="flex flex-col gap-1 p-2 text-xs pt-2 border-t border-border-light">
        <div className="flex items-center gap-1">
          Type:
          <Badge variant="outline" style={{ fontSize: "10px" }}>
            {AssociationType}
          </Badge>
        </div>
        <div className="flex items-center gap-1 w-full max-w-[100%]">
          Value:{" "}
          <Badge
            variant="outline"
            style={{ fontSize: "10px" }}
            className="w-auto max-w-[80%] truncate"
          >
            <span className="truncate inline-block">{AssociationValue}</span>
          </Badge>
        </div>
      </div>
    </div>
  );
  return (
    <div className="flex flex-col">
      {action}
      {subAction}
    </div>
  );
};

export const TxnActionItem = ({
  item,
  wrap,
  maxInnerTxnsToDisplay = 5,
  showAllInnerTxns = false,
}: ActivityActionProps) => {
  const formatMessage = (
    performerUserKey?: string | null,
    action?: string | JSX.Element,
    receiverUserKey?: string | null,
    subAction?: string | JSX.Element,
    className?: string,
    actionClassName?: string,
  ) => {
    return (
      <div className={cn("flex", wrap && "flex-wrap")}>
        {performerUserKey && (
          <SocialCard showAvatar={true} publicKey={performerUserKey} />
        )}
        {action && (
          <span
            className={`flex items-center whitespace-nowrap leading-8 ${cn(
              !!performerUserKey && "ml-2",
              !!receiverUserKey && "mr-2",
            )}`}
          >
            {action}
          </span>
        )}
        {receiverUserKey && (
          <SocialCard showAvatar={true} publicKey={receiverUserKey} />
        )}
        {subAction && (
          <span className={"whitespace-nowrap leading-8"}>{subAction}</span>
        )}
      </div>
    );
  };

  const parsePostAssociation = (rawTxn: any) => {
    if (
      !rawTxn ||
      !rawTxn.AssociationType ||
      !rawTxn.AssociationValue ||
      !rawTxn.PostHashHex
    ) {
      return {
        type: "",
        value: "a post association",
        postId: "",
      };
    }

    const associationType = rawTxn.AssociationType;
    const associationValue = rawTxn.AssociationValue;
    const postId = rawTxn.PostHashHex;

    const formattedValue =
      associationType === "REACTION" &&
      (AssociationReactionValue as any)[associationValue]
        ? (AssociationReactionValue as any)[associationValue]
        : associationValue;

    return {
      postId,
      type: associationType.replaceAll("_", " ").toLowerCase(),
      value: formattedValue,
    };
  };

  const formatTxn = (txn: any) => {
    const userKey = txn.publicKey;

    if (!txn.txnType) {
      return <>Transaction type is missing</>;
    }

    switch (TRANSACTION_TYPES_BY_INDEX[txn.txnType]) {
      case TRANSACTION_TYPE.TxnTypeCreateUserAssociation: {
        /**** TESTED ****/
        const { TargetUserPublicKeyBase58Check } = txn.txIndexMetadata || {};
        return getFullActionForAssociation(
          txn,
          formatMessage(
            userKey,
            TargetUserPublicKeyBase58Check
              ? "created an association with"
              : "created an association with another user",
            TargetUserPublicKeyBase58Check,
          ),
        );
      }
      case TRANSACTION_TYPE.TxnTypeDeleteUserAssociation: {
        /**** TESTED ****/
        const { TargetUserPublicKeyBase58Check } = txn.txIndexMetadata || {};

        return getFullActionForAssociation(
          txn,
          formatMessage(
            userKey,
            TargetUserPublicKeyBase58Check
              ? "deleted an association with"
              : "deleted an association with another user",
            TargetUserPublicKeyBase58Check,
          ),
        );
      }
      case TRANSACTION_TYPE.TxnTypeCreatePostAssociation: {
        /**** TESTED ****/
        const { postId } = parsePostAssociation(txn?.txIndexMetadata);

        return getFullActionForAssociation(
          txn,
          formatMessage(
            userKey,
            postId ? (
              <>
                created an association to a&nbsp;
                <DiamondPostLink postId={postId} />
              </>
            ) : (
              "created an association to a post"
            ),
          ),
        );
      }
      case TRANSACTION_TYPE.TxnTypeDeletePostAssociation: {
        /**** TESTED ****/
        const { postId, type, value } = parsePostAssociation(
          txn?.txIndexMetadata,
        );

        return getFullActionForAssociation(
          txn,
          formatMessage(
            userKey,
            postId ? (
              <>
                deleted an association from a&nbsp;
                <DiamondPostLink postId={postId} />
              </>
            ) : (
              "deleted an association from a post"
            ),
          ),
        );
      }
      case TRANSACTION_TYPE.TxnTypeLike: {
        /**** TESTED ****/
        return formatMessage(
          userKey,
          <>
            liked a&nbsp;
            <DiamondPostLink postId={txn.txIndexMetadata?.PostHashHex || ""} />
          </>,
        );
      }
      case TRANSACTION_TYPE.TxnTypeBasicTransfer: {
        /**** TESTED ****/
        const outputs = (txn.outputs || []) as Array<BasicTransferOutput>;
        const receiver = maxBy(
          outputs.filter((o) => o.public_key !== txn.publicKey),
          "amount_nanos",
        );
        const amountFormatted = desoNanosToDeso(
          receiver?.amount_nanos as string,
        );
        const diamondsSent = txn.txIndexMetadata?.DiamondLevel || 0;

        if (diamondsSent) {
          return formatMessage(
            userKey,
            <>
              sent&nbsp;
              <div className="flex">
                {range(diamondsSent).map((i) => (
                  <Gem size={16} key={i} />
                ))}
              </div>
              &nbsp;to
            </>,
            receiver?.public_key,
            <>
              &nbsp;on a&nbsp;
              <DiamondPostLink
                postId={txn.txIndexMetadata?.PostHashHex || ""}
              />
            </>,
          );
        }

        return formatMessage(
          userKey,
          <>
            sent <div className="text-green-600 mx-1">{amountFormatted}</div>{" "}
            $DESO to{" "}
          </>,
          receiver?.public_key,
        );
      }
      case TRANSACTION_TYPE.TxnTypeBitcoinExchange: {
        return formatMessage(userKey, "burned BTC for DESO");
      }
      case TRANSACTION_TYPE.TxnTypeNewMessage: {
        /**** TESTED ****/
        const isDM = txn.txnMeta.NewMessageType === 0;
        const isCreation = txn.txnMeta.NewMessageOperation === 0;

        const sender = publicKeyByteArrayToBase58Check(
          txn.txnMeta.SenderAccessGroupOwnerPublicKey,
        );
        const receiver = publicKeyByteArrayToBase58Check(
          txn.txnMeta.RecipientAccessGroupOwnerPublicKey,
        );

        const subAction = !isDM ? (
          <>
            's group called{" "}
            <b>
              {String.fromCharCode(
                ...txn.txnMeta.RecipientAccessGroupKeyName.filter(
                  (e: number) => e !== 0, // remove trailing zeros
                ),
              )}
            </b>
          </>
        ) : (
          ""
        );

        return formatMessage(
          sender,
          `${isCreation ? "sent" : "updated"} a message to`,
          receiver,
          subAction,
        );
      }
      case TRANSACTION_TYPE.TxnTypePrivateMessage: {
        /**** TESTED ****/
        const receiver = formatTxnUsername(txn.txnMeta.RecipientPublicKey);
        return formatMessage(userKey, "sent a message to", receiver);
      }
      case TRANSACTION_TYPE.TxnTypeUpdateProfile: {
        /**** TESTED ****/
        return formatMessage(userKey, "updated a profile");
      }
      case TRANSACTION_TYPE.TxnTypeSubmitPost: {
        /**** TESTED ****/
        let action: JSX.Element | string = "";

        const isRepost = !!txn.extraData.RecloutedPostHash;
        const postId = txn.txnMeta?.PostHashToModify
          ? txn.txIndexMetadata?.PostHashBeingModifiedHex
          : txn.transactionHash;

        if (isRepost) {
          action = (
            <>
              reposted a&nbsp;
              <DiamondPostLink postId={postId} />
            </>
          );
        } else if (txn.txIndexMetadata?.ParentPostHashHex) {
          action = (
            <>
              commented on a&nbsp;
              <DiamondPostLink postId={postId} />
            </>
          );
        } else if (txn.txIndexMetadata?.PostHashToModify) {
          action = (
            <>
              edited a&nbsp;
              <DiamondPostLink postId={postId} />
            </>
          );
        } else {
          action = (
            <>
              submitted a&nbsp;
              <DiamondPostLink postId={postId} />
            </>
          );
        }

        return formatMessage(userKey, action);
      }
      case TRANSACTION_TYPE.TxnTypeFollow: {
        /**** TESTED ****/
        return formatMessage(
          userKey,
          txn.txnMeta.IsUnfollow ? "unfollowed" : "followed",
          formatTxnUsername(txn.txnMeta.FollowedPublicKey),
        );
      }
      case TRANSACTION_TYPE.TxnTypeCreatorCoin: {
        /**** TESTED ****/
        const operation =
          txn.txIndexMetadata?.OperationType === "buy" ? (
            <>
              bought {desoNanosToDeso(txn.txIndexMetadata.DeSoToSellNanos)}{" "}
              $DESO worth of
            </>
          ) : (
            <>
              sold {desoNanosToDeso(txn.txIndexMetadata.CreatorCoinToSellNanos)}{" "}
              of{" "}
            </>
          );
        const creator = formatTxnUsername(txn.txnMeta.ProfilePublicKey);

        return formatMessage(userKey, operation, creator, "'s creator coin");
      }
      case TRANSACTION_TYPE.TxnTypeSwapIdentity: {
        /**** TESTED ****/
        return formatMessage(userKey, "swapped identity");
      }
      case TRANSACTION_TYPE.TxnTypeUpdateGlobalParams: {
        /**** TESTED ****/
        return formatMessage(userKey, "updated global params");
      }
      case TRANSACTION_TYPE.TxnTypeCreateNFT: {
        /**** TESTED ****/
        return formatMessage(
          userKey,
          <>
            created an&nbsp;
            <DiamondNFTLink NFTId={txn.txIndexMetadata?.NFTPostHashHex || ""} />
          </>,
        );
      }
      case TRANSACTION_TYPE.TxnTypeUpdateNFT: {
        /**** TESTED ****/
        let action: JSX.Element;

        if (txn.txnMeta?.IsForSale) {
          action = (
            <>
              put an&nbsp;
              <DiamondNFTLink
                NFTId={txn.txIndexMetadata?.NFTPostHashHex}
              />{" "}
              &nbsp;on sale
            </>
          );
        } else {
          action = (
            <>
              took &nbsp;
              <DiamondNFTLink
                NFTId={txn.txIndexMetadata?.NFTPostHashHex || ""}
              />{" "}
              &nbsp;off market
            </>
          );
        }

        return formatMessage(userKey, action);
      }
      case TRANSACTION_TYPE.TxnTypeAcceptNFTBid: {
        /**** TESTED ****/
        return formatMessage(
          userKey,
          <>
            sold&nbsp;
            <DiamondNFTLink NFTId={txn.txIndexMetadata?.NFTPostHashHex || ""} />
          </>,
        );
      }
      case TRANSACTION_TYPE.TxnTypeNFTBid: {
        /**** TESTED ****/
        return formatMessage(
          userKey,
          <>
            bid on an&nbsp;
            <DiamondNFTLink NFTId={txn.txIndexMetadata?.NFTPostHashHex || ""} />
          </>,
        );
      }
      case TRANSACTION_TYPE.TxnTypeNFTTransfer: {
        /**** TESTED ****/
        return formatMessage(
          userKey,
          <>
            bid on an&nbsp;
            <DiamondNFTLink NFTId={txn.txIndexMetadata?.NFTPostHashHex || ""} />
          </>,
        );
      }
      case TRANSACTION_TYPE.TxnTypeAcceptNFTTransfer:
        /**** TESTED ****/
        return formatMessage(
          userKey,
          <>
            accepted an&nbsp;
            <DiamondNFTLink NFTId={txn.txIndexMetadata?.NFTPostHashHex || ""} />
            &nbsp;transfer
          </>,
        );
      case TRANSACTION_TYPE.TxnTypeBurnNFT:
        /**** TESTED ****/
        return formatMessage(
          userKey,
          <>
            burned an&nbsp;
            <DiamondNFTLink NFTId={txn.txIndexMetadata?.NFTPostHashHex || ""} />
          </>,
        );
      case TRANSACTION_TYPE.TxnTypeAuthorizeDerivedKey: {
        /**** TESTED ****/
        return formatMessage(userKey, "authorized a derived key");
      }
      case TRANSACTION_TYPE.TxnTypeDAOCoinTransfer: {
        /**** TESTED ****/
        return formatMessage(
          userKey,
          <>
            transferred{" "}
            {baseUnitsToTokensFormatted(txn.txnMeta.DAOCoinToTransferNanos)} of
            <div className="ml-2">
              <SocialCard
                showAvatar={true}
                publicKey={formatTxnUsername(txn.txnMeta.ProfilePublicKey)}
              />
            </div>
            's token to
          </>,
          formatTxnUsername(txn.txnMeta.ReceiverPublicKey),
        );
      }
      case TRANSACTION_TYPE.TxnTypeDAOCoinLimitOrder: {
        /**** TESTED ****/
        const isCancelling = !!txn.txnMeta.CancelOrderID;

        if (isCancelling) {
          return formatMessage(userKey, "cancelled an order");
        }

        const buyingPublicKey = isZeroPublicKey(
          txn.txnMeta.BuyingDAOCoinCreatorPublicKey,
        )
          ? null
          : txn.txIndexMetadata?.BuyingDAOCoinCreatorPublicKey;

        const sellingPublicKey = isZeroPublicKey(
          txn.txnMeta.SellingDAOCoinCreatorPublicKey,
        )
          ? null
          : txn.txIndexMetadata?.SellingDAOCoinCreatorPublicKey;

        const operationType = txn.txnMeta.OperationType === 1 ? "ASK" : "BID";
        let quantityToFill = baseUnitsToTokensFormatted(
          txn.txnMeta.QuantityToFillInBaseUnits,
        );
        if (
          (operationType === "ASK" && !sellingPublicKey) ||
          (operationType === "BID" && !buyingPublicKey)
        ) {
          quantityToFill = toFixedTrimTrailingZeroes(
            desoNanosToDeso(txn.txnMeta.QuantityToFillInBaseUnits),
          );
        }
        let price: string | undefined;
        if (txn.txnMeta.ScaledExchangeRateCoinsToSellPerCoinToBuy !== "0x0") {
          price = scaledExchangeRateToPriceString(
            txn.txnMeta.ScaledExchangeRateCoinsToSellPerCoinToBuy,
            buyingPublicKey || "DESO",
            sellingPublicKey || "DESO",
            operationType,
          );
        }

        const getSocialCard = (
          isBuyingCoin: boolean,
          includeToken: boolean,
        ) => {
          const publicKey = isBuyingCoin ? buyingPublicKey : sellingPublicKey;
          return publicKey ? (
            <>
              <div className={`${includeToken ? "ml-1" : "mx-1"}`}>
                <SocialCard showAvatar={true} publicKey={publicKey} />
              </div>
              {includeToken ? "'s token" : " "}
            </>
          ) : (
            <span className="text-muted-foreground inline-block mx-1">
              $DESO
            </span>
          );
        };
        const isAsk = operationType === "ASK";
        const firstAction = isAsk ? "sell" : "buy";
        const secondAction = isAsk ? "buy" : "sell";
        const formattedQuantityAndFirstAction = (
          <span
            className={cn("ml-1", isAsk ? "text-red-500" : "text-green-500")}
          >
            {firstAction} {Number(quantityToFill).toLocaleString()}
          </span>
        );
        if (!price) {
          // handle market order.
          return formatMessage(
            userKey,
            <>
              submitted a market order to
              {formattedQuantityAndFirstAction}
              {getSocialCard(!isAsk, true)} worth of
              {getSocialCard(isAsk, true)}
            </>,
          );
        }
        let priceNumerator = getSocialCard(isAsk, false);
        let priceDenominator = getSocialCard(!isAsk, false);
        if (
          (!isAsk &&
            (buyingPublicKey === USDC_PUBLIC_KEY ||
              (!buyingPublicKey && sellingPublicKey !== USDC_PUBLIC_KEY) ||
              (buyingPublicKey === FOCUS_PUBLIC_KEY &&
                sellingPublicKey &&
                sellingPublicKey !== USDC_PUBLIC_KEY))) ||
          (isAsk &&
            (sellingPublicKey === USDC_PUBLIC_KEY ||
              (!sellingPublicKey && buyingPublicKey !== USDC_PUBLIC_KEY) ||
              (sellingPublicKey === FOCUS_PUBLIC_KEY &&
                buyingPublicKey &&
                buyingPublicKey !== USDC_PUBLIC_KEY)))
        ) {
          priceNumerator = getSocialCard(!isAsk, false);
          priceDenominator = getSocialCard(isAsk, false);
          price = scaledExchangeRateToPriceString(
            txn.txnMeta.ScaledExchangeRateCoinsToSellPerCoinToBuy,
            buyingPublicKey || "DESO",
            sellingPublicKey || "DESO",
            operationType === "ASK" ? "BID" : "ASK",
          );
        }
        return formatMessage(
          userKey,
          <>
            submitted an order to
            {formattedQuantityAndFirstAction}
            {getSocialCard(!isAsk, true)} and
            <span
              className={cn("ml-1", isAsk ? "text-green-500" : "text-red-500")}
            >
              {secondAction}
            </span>
            {getSocialCard(isAsk, true)} at a price of{" "}
            <span
              className={cn("ml-1", !isAsk ? "text-red-500" : "text-green-500")}
            >
              {Number(price).toLocaleString()}
            </span>{" "}
            {priceNumerator} <> per </>
            {priceDenominator}
          </>,
        );
      }
      case TRANSACTION_TYPE.TxnTypeCreatorCoinTransfer: {
        /**** TESTED ****/
        return formatMessage(
          userKey,
          <>
            transferred{" "}
            {desoNanosToDeso(txn.txIndexMetadata?.CreatorCoinToTransferNanos)}{" "}
            of
            <div className="ml-2">
              <SocialCard
                showAvatar={true}
                publicKey={formatTxnUsername(txn.txnMeta.ProfilePublicKey)}
              />
            </div>
            's creator coin to
          </>,
          formatTxnUsername(txn.txnMeta.ReceiverPublicKey),
        );
      }
      case TRANSACTION_TYPE.TxnTypeBlockReward: {
        /**** TESTED ****/
        const affectedPublicKeys = txn.affectedPublicKeys?.nodes?.filter(
          (e: any) => isMaybeDeSoPublicKey(e.publicKey),
        );
        const receiver =
          affectedPublicKeys && affectedPublicKeys.length
            ? affectedPublicKeys[0]?.publicKey
            : item.outputs?.[0]?.public_key || "";
        return formatMessage(receiver, "received a block reward");
      }
      case TRANSACTION_TYPE.TxnTypeMessagingGroup: {
        /**** TESTED ****/
        return formatMessage(userKey, "performed a messaging group operation");
      }
      case TRANSACTION_TYPE.TxnTypeAccessGroup: {
        /**** TESTED ****/
        return formatMessage(userKey, "performed an access group operation");
      }
      case TRANSACTION_TYPE.TxnTypeDAOCoin: {
        /**** TESTED ****/
        switch (txn.txnMeta.OperationType) {
          case 0:
            return formatMessage(
              userKey,
              `minted ${baseUnitsToTokensFormatted(
                txn.txnMeta.CoinsToMintNanos,
              )} of their token`,
            );
          case 1:
            const coinBurned = formatTxnUsername(txn.txnMeta.ProfilePublicKey);
            const coinBurnedIsOwn = coinBurned === userKey;
            return formatMessage(
              userKey,
              <>
                burned{" "}
                {baseUnitsToTokensFormatted(txn.txnMeta.CoinsToBurnNanos)} of{" "}
                {coinBurnedIsOwn ? (
                  "their "
                ) : (
                  <>
                    <div className="ml-2">
                      <SocialCard showAvatar={true} publicKey={coinBurned} />
                    </div>
                    's{" "}
                  </>
                )}
                token
              </>,
            );
          case 2:
            return formatMessage(userKey, "disabled minting of their token");
          case 3:
            return formatMessage(
              userKey,
              <>
                updated the transfer restriction status of their token to
                <b className="ml-1">
                  {txn.txIndexMetadata.TransferRestrictionStatus}
                </b>
              </>,
            );
        }
        return formatMessage(
          userKey,
          "performed an unknown DeSo Token operation",
        );
      }
      case TRANSACTION_TYPE.TxnTypeAccessGroupMembers:
        const actionByTypes = {
          "2": "added members to",
          "3": "removed members from",
          "4": "updated members in",
        };

        const action =
          (actionByTypes as any)?.[
            txn.txIndexMetadata.AccessGroupMemberOperationType.toString()
          ] || "performed an action with members in";

        const groupName = atob(txn.txnMeta.AccessGroupKeyName);

        return formatMessage(
          userKey,
          <>
            {action} an access group <b className="ml-1">{groupName}</b>
          </>,
        );
      case TRANSACTION_TYPE.TxnTypeRegisterAsValidator:
        return formatMessage(userKey, "registered as a validator");
      case TRANSACTION_TYPE.TxnTypeUnregisterAsValidator:
        return formatMessage(userKey, "unregistered as a validator");
      case TRANSACTION_TYPE.TxnTypeUnjailValidator:
        return formatMessage(userKey, "unjailed themselves as a validator");
      case TRANSACTION_TYPE.TxnTypeStake:
        const stakeAmountFormatted = desoNanosToDeso(
          parseInt(txn.txIndexMetadata?.StakeAmountNanos as string),
        );
        return formatMessage(userKey, `staked ${stakeAmountFormatted} $DESO`);
      case TRANSACTION_TYPE.TxnTypeUnstake:
        const unstakeAmountFormatted = desoNanosToDeso(
          parseInt(txn.txIndexMetadata?.UnstakeAmountNanos as string),
        );
        return formatMessage(
          userKey,
          `unstaked ${unstakeAmountFormatted} $DESO`,
        );
      case TRANSACTION_TYPE.TxnTypeUnlockStake:
        const unlockStakeAmountFormatted = desoNanosToDeso(
          parseInt(txn.txIndexMetadata?.TotalUnlockedAmountNanos as string),
        );
        return formatMessage(
          userKey,
          `unlocked stake of ${unlockStakeAmountFormatted} $DESO`,
        );
      case TRANSACTION_TYPE.TxnTypeCoinLockup:
        const profilePublicKey = parsePublicKeyBytesToPublicKey(
          txn.txnMeta.ProfilePublicKey,
        );

        const recipientPublicKey = parsePublicKeyBytesToPublicKey(
          txn.txnMeta.RecipientPublicKey,
        );

        const isLockupAndTransfer = recipientPublicKey !== userKey;

        return formatMessage(
          userKey,
          <>
            locked up{" "}
            {baseUnitsToTokensFormatted(txn.txnMeta.LockupAmountBaseUnits)} of
            <div className="ml-1">
              <SocialCard publicKey={profilePublicKey} />
            </div>
            's tokens until{" "}
            {new Date(
              txn.txnMeta.UnlockTimestampNanoSecs / 1000000,
            ).toDateString()}
            {isLockupAndTransfer && (
              <>
                {" "}
                and transferred them to{" "}
                <div className="ml-1">
                  <SocialCard publicKey={recipientPublicKey} />
                </div>
              </>
            )}
          </>,
        );
      case TRANSACTION_TYPE.TxnTypeCoinLockupTransfer:
        return formatMessage(
          userKey,
          <>
            transferred{" "}
            {baseUnitsToTokensFormatted(
              txn.txnMeta.LockedCoinsToTransferBaseUnits,
            )}{" "}
            of
            <div className="ml-1">
              <SocialCard
                publicKey={parsePublicKeyBytesToPublicKey(
                  txn.txnMeta.ProfilePublicKey,
                )}
              />
            </div>
            's tokens to{" "}
            <div className="mx-1">
              <SocialCard
                publicKey={parsePublicKeyBytesToPublicKey(
                  txn.txnMeta.RecipientPublicKey,
                )}
              />
            </div>
            that will unlock on{" "}
            {new Date(
              txn.txnMeta.UnlockTimestampNanoSecs / 1000000,
            ).toDateString()}
          </>,
        );
      case TRANSACTION_TYPE.TxnTypeCoinUnlock:
        return formatMessage(
          userKey,
          <>
            performed a coin unlock of{" "}
            <div className="ml-1">
              <SocialCard
                publicKey={parsePublicKeyBytesToPublicKey(
                  txn.txnMeta.ProfilePublicKey,
                )}
              />
            </div>
            's tokens
          </>,
        );
      case TRANSACTION_TYPE.TxnTypeUpdateCoinLockupParams:
        return formatMessage(userKey, "updated coin lockup params");
      case TRANSACTION_TYPE.TxnTypeAtomicTxnsWrapper:
        const innerTxns = txn.innerTransactions?.nodes || [];
        const innerTxnsToDisplay = showAllInnerTxns
          ? innerTxns
          : innerTxns.slice(0, maxInnerTxnsToDisplay);
        const innerTxnsFormatted =
          innerTxnsToDisplay.map((innerTxn: any) => formatTxn(innerTxn)) || [];

        return (
          <>
            {!wrap && (
              <div className={cn("flex", wrap && "flex-wrap")}>
                A series of {txn.txnMeta.Txns.length} transactions were executed
                atomically.
              </div>
            )}
            {!!innerTxnsFormatted?.length && (
              <div
                className={cn(
                  "flex-col flex-wrap items-start justify-start border overflow-y-hidden rounded-lg p-2 py-1 my-2",
                  wrap ? "" : "max-w-[600px]",
                )}
              >
                {wrap && (
                  <div className={cn("flex", wrap && "flex-wrap")}>
                    A series of {txn.txnMeta.Txns.length} transactions were
                    executed atomically.
                  </div>
                )}
                {innerTxnsFormatted?.map((txn: any, ii: number) => (
                  <div
                    key={`${txn.transactionHash}-inner-txn-${ii}`}
                    className={cn("flex items-start flex-wrap")}
                  >
                    {txn}
                    <Link
                      to={`/txn/${innerTxnsToDisplay[ii].transactionHash}`}
                      className="ml-1 pt-2 hover:underline"
                    >
                      (view)
                    </Link>
                  </div>
                ))}
              </div>
            )}
            {!!innerTxnsFormatted.length &&
              !showAllInnerTxns &&
              innerTxns.length > maxInnerTxnsToDisplay && (
                <div className="mb-1">
                  <span className="text-muted">
                    and {innerTxns.length - maxInnerTxnsToDisplay} more...
                  </span>
                </div>
              )}
          </>
        );
      case TRANSACTION_TYPE.TxnTypeUpdateBitcoinUSDExchangeRate:
      default:
        return <span>Unhandled transaction</span>;
    }
  };
  return formatTxn(item);
};
